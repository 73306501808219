import React from "react";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import Image from "gatsby-plugin-sanity-image"
import styled from "styled-components";
import Layout from "../components/Layouts"
import SEO from "../components/SEO"
import { FacebookShareButton, FacebookIcon } from 'react-share';

const BlogContent = styled.div`
  
  
`;
const BlogPost = styled.div`
  background-color: white;
  padding: 20px;
  margin: 40px auto;
  max-width: 90%;
  text-align: center;
`;
const BlogImage = styled(Image)`
  height: 300px;
  margin: auto;
`;
const Title = styled.div`
  text-align: center;
  margin: 10px;
`;
const Facebook = styled.div`
  text-align: center;
  margin-top: 100px;
`;
const BottomSpacer = styled.div`
  margin-bottom:500px;
  @media (max-width: 420px) {
    margin-bottom: 600px;
  }
  @media (max-width: 380px) {
    margin-bottom: 600px;
  }
`
export default function SingleBlogPage({ data }) {
  
  const url = typeof window !== 'undefined' ? window.location.href : '';
  
  return (
    <>
    <SEO title="Blog" />
    <Layout>
    
      
      <BlogPost>
        <BlogImage {...data.sanityPost.mainImage} alt={data.sanityPost.title} />
        <Title><h1>{data.sanityPost.title}</h1></Title>
        
        <h5>{data.sanityPost.publishedAt}</h5>
        <BlogContent>
        <BlockContent blocks={data.sanityPost._rawBody} />
        </BlogContent>
        <Facebook>
      <FacebookShareButton url={url}>
        <FacebookIcon size={64} round={true} />
        <h3>Share to Facebook!</h3>
      </FacebookShareButton>
      </Facebook>
      </BlogPost>
    <BottomSpacer/>
    </Layout>
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    sanityPost(slug: { current: { eq: $slug } }) {
      title
      publishedAt(formatString: "dddd MMMM Do, YYYY")
      _rawBody(resolveReferences: { maxDepth: 10 })
      mainImage {
          ...ImageWithPreview
        }
    }
  }
`;
